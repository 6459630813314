import {
  colors,
  ConfettiBigSectionWrapper,
  EmphasizedText,
  PageHeader,
  TextBody
} from "@xolvio/xolvio-ui";
import React from "react";
import styled from "styled-components";

export const ConfettiHeader = ({ text, highlights, body }) => (
  <ConfettiBigSectionWrapper>
    <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
      <EmphasizedText text={text} highlight={highlights} />
    </Header>
    {body && <HeaderBody>{body}</HeaderBody>}
  </ConfettiBigSectionWrapper>
);

const Header = styled(PageHeader)`
  background: ${colors.AcceptanceGreenUltimatelyLight} !important;
  text-align: center !important;
  max-width: 750px !important;
  margin-bottom: 0;
`;

const HeaderBody = styled(TextBody)`
  max-width: 750px !important;
  text-align: center !important;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
`;
