import {
  ButtonPrimaryLink,
  CenteredContentWrapper,
  EmphasizedText,
  ImageConfettiWrapper,
  ListChecked,
  PageHeader,
  SectionHeader,
  SectionHeaderSmall,
  spacing,
  Spacing,
  Subheading,
  TextBody,
  ToutImageConfettiWrapper,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  TwoColumnsWrapper,
  colors,
  device,
} from "@xolvio/xolvio-ui";
import styled from "styled-components";
import { pageData } from "../pages-content/products";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Image from "gatsby-image";
import { breakpoints } from "@xolvio/xolvio-ui";
import { ConfettiHeader } from "./ConfettiHeader";

export const ProductsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        xspecs: file(relativePath: { eq: "xspecs@2x.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bookCover: file(relativePath: { eq: "qualit-faster-book-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        chimpImage: file(relativePath: { eq: "man_reading_code.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => <Products data={data} />}
  />
);

const Products = ({ data }) => (
  <Wrapper>
    <ConfettiHeader {...pageData.header} />

    <Spacing />

    <TwoColumnsWithImage
      contentNode={
        <XspecsContentWrapper>
          {pageData.xspecs.logo}
          <Subheading>{pageData.xspecs.subheading}</Subheading>
          <SectionHeaderStyled>
            <EmphasizedText
              highlight={pageData.xspecs.highlights}
              text={pageData.xspecs.header}
            />
          </SectionHeaderStyled>
          <TextBody>{pageData.xspecs.body}</TextBody>
          <ButtonPrimaryLink
            as={"a"}
            style={{ marginTop: 15 }}
            href={pageData.xspecs.buttonUrl}
          >
            {pageData.xspecs.buttonText}
          </ButtonPrimaryLink>
        </XspecsContentWrapper>
      }
      imageNode={
        <ImageConfettiWrapper
          flipConfetti
          topConfettiSide={"left"}
          elementWithBackgroundImage={
            <BackgroundImage fluid={data.xspecs.childImageSharp.fluid} />
          }
        />
      }
    />

    <SpacingSmaller />

    <CenteredContentWrapper>
      <XspecsLists height={"auto"}>
        <ListWithIconAndTitle
          header={pageData.xspecs.list1.header}
          icon={pageData.xspecs.list1.icon}
          list={pageData.xspecs.list1.list}
        />

        <ListWithIconAndTitle
          header={pageData.xspecs.list2.header}
          icon={pageData.xspecs.list2.icon}
          list={pageData.xspecs.list2.list}
        />
      </XspecsLists>
    </CenteredContentWrapper>

    <Spacing />

    <ToutWrapper>
      <Spacing />

      <ToutContentWrapper>
        <TwoColumns height={"620px"} style={{ padding: 0 }}>
          <ToutImageConfettiWrapper
            image={
              <Image
                fluid={data.bookCover.childImageSharp.fluid}
                durationFadeIn={200}
              />
            }
          />

          <ToutContentColumn>
            <ContentColumnInner>
              {pageData.qualityFaster.logo}

              <SectionHeader style={{ marginTop: 19, marginBottom: 16 }}>
                <EmphasizedText
                  text={pageData.qualityFaster.header}
                  highlight={pageData.qualityFaster.headerHighlights}
                />
              </SectionHeader>

              <TextBodyStyled>{pageData.qualityFaster.body}</TextBodyStyled>

              <Button as={"a"} href={pageData.qualityFaster.buttonUrl}>
                {pageData.qualityFaster.buttonText}
              </Button>
            </ContentColumnInner>
          </ToutContentColumn>
        </TwoColumns>

        <Row height={"auto"} style={{ marginTop: 70 }}>
          <ContentColumn>
            <FirstGridContentColumnInner>
              <Subheading>{pageData.qualityFaster.grid.subheading}</Subheading>
              <QualityFasterSectionHeaderSmall>
                {pageData.qualityFaster.grid.heading}
              </QualityFasterSectionHeaderSmall>
            </FirstGridContentColumnInner>
          </ContentColumn>

          <ContentColumn>
            <ContentColumnInner>
              <ContentColumn>
                <ContentColumnInner>
                  <GridCell>
                    {pageData.qualityFaster.grid.elements[0].icon}

                    <GridCellContent>
                      <SmallHeader>
                        {pageData.qualityFaster.grid.elements[0].heading}
                      </SmallHeader>
                      <TextBodyStyled>
                        {pageData.qualityFaster.grid.elements[0].body}
                      </TextBodyStyled>
                    </GridCellContent>
                  </GridCell>
                </ContentColumnInner>
              </ContentColumn>
            </ContentColumnInner>
          </ContentColumn>
        </Row>

        <Row height={"auto"}>
          <ContentColumn>
            <ContentColumnInner>
              <GridCell>
                {pageData.qualityFaster.grid.elements[1].icon}

                <GridCellContent>
                  <SmallHeader>
                    {pageData.qualityFaster.grid.elements[1].heading}
                  </SmallHeader>
                  <TextBodyStyled>
                    {pageData.qualityFaster.grid.elements[1].body}
                  </TextBodyStyled>
                </GridCellContent>
              </GridCell>
            </ContentColumnInner>
          </ContentColumn>

          <ContentColumn>
            <ContentColumnInner>
              <GridCell>
                {pageData.qualityFaster.grid.elements[2].icon}
                <GridCellContent>
                  <SmallHeader>
                    {pageData.qualityFaster.grid.elements[2].heading}
                  </SmallHeader>
                  <TextBodyStyled>
                    {pageData.qualityFaster.grid.elements[2].body}
                  </TextBodyStyled>
                </GridCellContent>
              </GridCell>
            </ContentColumnInner>
          </ContentColumn>
        </Row>
      </ToutContentWrapper>

      <Spacing />
    </ToutWrapper>

    {/*<Spacing />*/}

    {/*<TwoColumnsWithImage*/}
    {/*  imagePosition={"right"}*/}
    {/*  contentNode={*/}
    {/*    <TwoColumnContentNodeWrapper >*/}
    {/*      {pageData.chimp.logo}*/}
    {/*      <Subheading>{pageData.chimp.subheading}</Subheading>*/}
    {/*      <SectionHeaderStyled>*/}
    {/*        <EmphasizedText*/}
    {/*          highlight={pageData.chimp.highlights}*/}
    {/*          text={pageData.chimp.header}*/}
    {/*        />*/}
    {/*      </SectionHeaderStyled>*/}
    {/*      <Desktop>*/}
    {/*        <ChimpContent />*/}
    {/*      </Desktop>*/}
    {/*    </TwoColumnContentNodeWrapper>*/}
    {/*  }*/}
    {/*  imageNode={*/}
    {/*    <ImageConfettiWrapper*/}
    {/*      elementWithBackgroundImage={<BackgroundImage*/}
    {/*        fluid={data.chimpImage.childImageSharp.fluid}*/}
    {/*      />}*/}
    {/*    />*/}
    {/*  }*/}
    {/*/>*/}

    {/*<Mobile>*/}
    {/*  <ChimpContent />*/}
    {/*</Mobile>*/}

    {/*<SpacingSmaller />*/}

    {/*<CenteredContentWrapper>*/}
    {/*  <TwoColumnList height={"auto"}  style={{padding:0}}>*/}
    {/*    <ListWithIconAndTitle*/}
    {/*      header={pageData.chimp.list1.header}*/}
    {/*      icon={pageData.chimp.list1.icon}*/}
    {/*      list={pageData.chimp.list1.list}*/}
    {/*    />*/}

    {/*    <ListWithIconAndTitle*/}
    {/*      header={pageData.chimp.list2.header}*/}
    {/*      icon={pageData.chimp.list2.icon}*/}
    {/*      list={pageData.chimp.list2.list}*/}
    {/*    />*/}
    {/*  </TwoColumnList>*/}
    {/*</CenteredContentWrapper>*/}

    {/*<Spacing />*/}

    {/*<TextToutFullwidth {...defaultTextToutFullwidthProps} />*/}
  </Wrapper>
);

const ChimpContent = () => (
  <>
    <TextBody>{pageData.chimp.body}</TextBody>
    <Button as={"a"} href={pageData.chimp.buttonUrl} style={{ marginTop: 14 }}>
      {pageData.chimp.buttonText}
    </Button>
  </>
);

const SpacingSmaller = styled(Spacing)`
  @media ${device.mobile} {
    height: 0;
  }

  @media ${device.tabletVertical} {
    height: 120px !important;
    max-height: 120px !important;
  }
`;

const XspecsContentWrapper = styled(TwoColumnContentNodeWrapper)`
  @media ${device.mobile} {
    margin-top: 60px;
  }
`;

const Row = styled(TwoColumnsWrapper)`
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${spacing.mobile.padding.default}px;

  @media ${device.tabletVertical} {
    align-items: center;
  }

  @media ${device.tablet} {
    align-items: flex-start;
  }
`;

const GridCellContent = styled.div`
  @media ${device.tablet} {
    padding-left: ${spacing.mobile.padding.default}px;
  }
`;

const GridCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;

  > svg {
    min-width: 100px;
    transform: translateY(20px);
  }

  @media ${device.tabletVertical} {
    padding-left: ${spacing.mobile.padding.default}px;
  }

  @media ${device.tablet} {
    align-items: flex-start;
    flex-direction: row;
  }
`;

const SmallHeader = styled(PageHeader)`
  font-size: 21px;
  background-color: ${colors.AcceptanceGreenUltimatelyLight} !important;

  @media ${device.mobile} {
    margin-top: 50px;
    font-size: 21px;
  }

  @media ${device.tablet} {
    font-size: 28px;
  }
`;

const TextBodyStyled = styled(TextBody)`
  font-family: "HK Grotesk";
  font-size: 18px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: #6b726f;
  margin-bottom: 0;
`;

const Button = styled(ButtonPrimaryLink)`
  margin-top: 32px;
  font-family: "HK Grotesk";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
`;

export const ListWithIconAndTitle = ({ header, icon, list, style }) => (
  <ListWrapperOuter style={style}>
    {icon}
    <ListWrapperInner>
      <SectionHeaderSmall>{header}</SectionHeaderSmall>
      <ListChecked>{list}</ListChecked>
    </ListWrapperInner>
  </ListWrapperOuter>
);

const TwoColumns = styled(TwoColumnsWrapper)`
  flex-direction: column;
`;

const TwoColumnList = styled(TwoColumnsWrapper)`
  padding: 0 !important;
  flex-direction: column;

  > * {
    &:nth-child(2) {
      @media ${device.mobile} {
        margin-top: 50px;
      }

      @media ${device.tabletVertical} {
        margin-top: 0px;
      }
    }
  }
`;

const XspecsLists = styled(TwoColumnList)`
  @media ${device.desktop} {
    margin-left: 100px;

    > * {
      &:nth-child(2) {
        margin-left: 100px;
      }
    }
  }
`;
const ToutWrapper = styled.div`
  background-color: ${colors.AcceptanceGreenUltimatelyLight};
`;

const ToutContentWrapper = styled(CenteredContentWrapper)``;

const Wrapper = styled.div``;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ToutContentColumn = styled(ContentColumn)`
  margin-top: 50px;

  @media ${device.tablet} {
    margin-left: 100px !important;
    margin-top: 0px;
    text-align: left;
    align-items: self-start;
  }
`;

const ContentColumnInner = styled.div`
  align-self: center;
  max-width: 457px;
  text-align: center;

  @media ${device.tablet} {
    margin-top: 0px;
    text-align: left;
    align-items: self-start;
  }
`;

const ListWrapperOuter = styled.div`
  display: flex;
  margin: 0;

  > svg {
    flex-shrink: 0;
    margin-top: 16px;
    margin-right: ${spacing.mobile.padding.default}px;
  }
`;
const ListWrapperInner = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    margin-top: -10px;
  }

  li {
    margin-left: -5px;
  }

  @media ${device.mobile} {
    > * {
      text-align: left;
    }
  }

  @media ${device.tablet} {
    h1 {
      margin-top: 15px;
    }
  }
`;

const SectionHeaderStyled = styled(SectionHeader)`
  margin-top: 20px;
  margin-bottom: 15px;
`;

const GridContentColumnInner = styled(ContentColumnInner)`
  @media ${device.desktop} {
    padding-left: ${2 * spacing.mobile.padding.default}px;
  }
`;

const FirstGridContentColumnInner = styled(GridContentColumnInner)`
  @media ${device.tabletVertical} {
    align-self: flex-start;
  }
`;

const QualityFasterSectionHeaderSmall = styled(SectionHeaderSmall)`
  @media ${device.tabletVertical} {
    font-size: 36px;
  }
`;

const Mobile = styled.div`
  text-align: center;
  padding: 48px 24px 24px;

  @media ${device.tabletVertical} {
    visibility: hidden;
    display: none;
  }
`;

const Desktop = styled.div`
  @media (max-width: ${breakpoints.tabletVertical.start - 1}px) {
    display: none;
  }
`;
