import React from "react";
import {
  BenefitsSmallIcon,
  ChimpLogo,
  DesignersShapesIcon,
  DevelopersIcon,
  FeaturesSmallIcon,
  QualityFasterLogo,
  TestersIcon,
  XSpecsLogo
} from "@xolvio/xolvio-ui";

export const pageData = {
  header: {
    text: "Up your own game",
    highlights: "your own",
    body:
      "Products and services that help you up your own game and push your own organization to delivery higher " +
      "quality software, faster."
  },

  xspecs: {
    logo: <XSpecsLogo />,
    highlights: ["velocity.", "bugs.", "quality."],
    header: "Improve velocity. Decrease bugs. Increase quality.",
    body:
      "XSpecs is a full-team collaborative toolset that simplifies the writing of specifications and integrates them into your team's existing workflow. This helps you improves velocity, decreases bugs, and increases quality.",
    buttonText: "Start using XSpecs for free",
    buttonUrl: "https://www.xspecs.ai",

    elementWithBackgroundImage: (
      <div style={{ backgroundImage: `url(assets/images/blog-zeroBug.png)` }} />
    ),

    list1: {
      header: "Features",
      icon: <FeaturesSmallIcon />,
      list: [
        "Simple collaborative specification writing",
        "Synchronized view across your tools",
        "Use automated tests as acceptance criteria and the definition of done"
      ]
    },

    list2: {
      header: "Benefits",
      icon: <BenefitsSmallIcon />,
      list: [
        "Single source of truth for specifications",
        "Bring your own process",
        "Deep integration with Git, Atlassian tools and Storybook"
      ]
    }
  },

  qualityFaster: {
    bookImage: (
      <img
        src="assets/images/book-composition@2x.png"
        alt={"#QualityFaster book cover"}
      />
    ),
    logo: <QualityFasterLogo />,
    header: "Deliver higher quality software, faster.",
    headerHighlights: ["quality", "faster."],
    body:
      "A complete full-team, full-cycle and full-stack guide to help you and your team increase the speed at " +
      "which you deliver software while simultaneously increasing quality.",
    buttonText: "Start learning for free",
    buttonUrl: "https://www.qualityfaster.com/",

    grid: {
      subheading: "#QualityFaster",
      heading: "For teams that love improving",
      elements: [
        {
          heading: "Developers",
          body:
            "Learn how to write high quality software at speed that is built to last the test of time.",
          icon: <DevelopersIcon />
        },
        {
          heading: "Product managers & designers",
          body:
            "Make a massive impact towards quality by employing the right techniques ahead of development cycles.",
          icon: <DesignersShapesIcon />
        },
        {
          heading: "Testers",
          body:
            "Acquire the skills to make you more effective and and contribute to all aspects of delivery,",
          icon: <TestersIcon />
        }
      ]
    }
  },

  chimp: {
    logo: <ChimpLogo />,
    highlights: ["simplifies", "developers"],
    header:
      "Open Source software that simplifies automated acceptance testing for developers",
    body: "",
    buttonText: "Fork me on GitHub",
    buttonUrl: "https://www.chimpjs.com",
    elementWithBackgroundImage: (
      <div style={{ backgroundImage: `url(assets/images/blog-zeroBug.png)` }} />
    ),

    list1: {
      header: "Features",
      icon: <FeaturesSmallIcon />,
      list: ["", "", ""]
    },

    list2: {
      header: "Benefits",
      icon: <BenefitsSmallIcon />,
      list: ["", "", ""]
    }
  }
};
