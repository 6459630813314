import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import {
  HeroContent,
  HeroContentWrapper,
  InlineHeroWithTwoColumnsHeader,
  TwoColumnSectionHeader,
} from "../../components/Services/ServicePageTemplate";
import {
  CenteredContentWrapper,
  ConfettiBigSectionWrapper,
  EmphasizedText,
  SectionHeader,
  Subheading,
  TextBody,
  TextToutFullwidth,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  device,
  Hero,
  TwoColumnsWrapper,
  FlipchartSmallIcon,
  BenefitsSmallIcon,
  DigitalStrategyAndArchitectureShape,
  ButtonPrimaryLink,
} from "@xolvio/xolvio-ui";
import {
  Header,
  HeaderBody,
  HeaderContentWrapper,
  Subheader,
} from "./experience-graph-managed-services";
import styled from "styled-components";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { contactUsFooterProps } from "../../pages-content/homepage";
import { OtherServices } from "../../components/Services/OtherServices";
import { MoveHack } from "../../components/Services";
import { ListWithIconAndTitle } from "../../components/Products";
import { StyledImgQualityFaster } from "./rapid-backend-development";

const SdlcArchitectureConsulting = () => (
  <LayoutDefault>
    <MoveHack topMobile={"-50px"} />
    <ConfettiBigSectionWrapper
      hideMobileConfetti
      style={{
        backgroundColor: "#ffffff",
        marginBottom: "30px",
        zIndex: 100,
      }}
    >
      <HeaderContentWrapper>
        <DigitalStrategyAndArchitectureShape height={"100px"} width={"100px"} />
        <Subheader>SERVICES / ACCELERATED SOFTWARE TRANSFORMATION</Subheader>
        <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
          SDLC & Architecture Consulting
        </Header>
        <HeaderBody>
          Leverage our enterprise-grade experience to transform your delivery.
        </HeaderBody>
      </HeaderContentWrapper>
    </ConfettiBigSectionWrapper>

    <MoveHack top={"80px"} topMobile={"40px"} />

    <Hero
      inline
      style={{ height: "520px" }}
      heading={
        <InlineHeroWithTwoColumnsHeader>
          <EmphasizedText
            text={"Do you want clockwork-like delivery?"}
            highlight={"clockwork-like"}
          />
        </InlineHeroWithTwoColumnsHeader>
      }
      children={
        <HeroContentWrapper>
          <div />

          <HeroContent>
            <TextBody bold>
              SDLC and software architecture are inextricably linked. In order
              to be successful, your engineering org needs to excel in both.
            </TextBody>
            <TextBody>
              We specialize in helping companies rapidly build complex,
              high-quality software that lasts decades. Let us enable you to
              deliver more business value.
            </TextBody>
          </HeroContent>
        </HeroContentWrapper>
      }
      elementWithBackgroundImage={null}
    />

    <MoveHack top="-200px" topPhone="120px" />

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <img
          style={{ width: "100%", maxWidth: "500px" }}
          src={"/assets/images/digital_strategy_2.svg"}
          width="500px"
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>WHAT WE DO</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={"---"}
              text={"Next-level architecture & SDLC prowess"}
            />
          </TwoColumnSectionHeader>
          <Text>
            At Xolvio, we have deep expertise in enterprise-grade, future-proof,
            malleable software architecture. As a consultancy, we will audit
            your SDLC and architecture, so as to provide recommendations on how
            to achieve infinite scalability, unparalleled performance, superior
            business intelligence, and clockwork-like delivery.
          </Text>
        </TwoColumnContentNodeWrapper>
      }
    />
    <MoveHack topMobile={"100px"} />

    <CenteredContentWrapper id="howWeDoIt">
      <ContentWrapper>
        <Subheading style={{ textAlign: "center" }}>HOW WE DO IT</Subheading>
        <SectionHeader>
          <EmphasizedText
            text={"3-day SDLC & architecture consulting package"}
            highlight={"package"}
          />
        </SectionHeader>
        <AlignToLeftOnMobile>
          Just like we deliver high-quality software rapidly, so too do we
          provide outstanding value swiftly through consulting. Our lightweight
          3-day SDLC & architecture consulting package is devised for maximum
          impact on your software engineering practice within the shortest time
          possible.
        </AlignToLeftOnMobile>

        <MoveHack top={"100px"} topMobile={"75px"} />
      </ContentWrapper>
    </CenteredContentWrapper>

    <MoveHack top={"-50px"} />

    <CenteredContentWrapper>
      <TwoColumnsWrapper>
        <ListWithIconAndTitle
          header={"Sessions"}
          icon={<FlipchartSmallIcon />}
          list={[
            "Schema storming",
            "Architecture review",
            "SDLC review",
            "Findings review",
            "Audit report presentation",
          ]}
        />

        <ListWithIconAndTitle
          header={"Deliverables"}
          icon={<BenefitsSmallIcon />}
          list={[
            "Audit results & readiness recommendations",
            "Supergraph project roadmap",
            "Governance recommendations",
            "Notes & artifacts from sessions",
          ]}
        />
      </TwoColumnsWrapper>
    </CenteredContentWrapper>

    <MoveHack top={"-100px"} topMobile={"100px"} />

    <TwoColumnsWithImage
      style={{ position: "relative" }}
      imagePosition={"left"}
      height={"600px"}
      imageHeight={"600px"}
      imageNode={
        <StyledImgQualityFaster>
          <img
            src={"/assets/images/quality-faster-general.svg"}
            alt="Quality Faster model"
          />
        </StyledImgQualityFaster>
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>ACCELERATED SOFTWARE TRANSFORMATION</Subheading>
          <TwoColumnSectionHeader>
            Rapidly build complex, high-quality software
          </TwoColumnSectionHeader>
          <MoveHack top={"25px"} />
          <TextBody>
            Refined and perfected over more than a decade now, our Quality
            Faster methodology innovates on custom software development by
            combining collaborative requirements facilitation, software quality
            best practices, and malleable architecture expertise.
          </TextBody>
          <MoveHack top={"25px"} />
          <ButtonPrimaryLink href={"/how-we-work"} as={"a"}>
            Find out more
          </ButtonPrimaryLink>
        </TwoColumnContentNodeWrapper>
      }
    />

    <OtherServices
      services={[
        "Apollo GraphQL Consulting",
        "Experience Graph Managed Services",
        "GraphQL Security Review & Remediation",
        "Rapid Backend Development",
        "Legacy Systems Modernization",
      ]}
    />

    <TextToutFullwidth {...contactUsFooterProps} />
  </LayoutDefault>
);

export default SdlcArchitectureConsulting;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

const AlignToLeftOnMobile = styled(TextBody)`
  max-width: 100%;
  text-align: center;

  @media ${device.mobile} {
    text-align: left;
  }
`;
